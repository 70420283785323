/**
 * Buttons
*/

.btn {
    display: inline-block;
    background:$black;
    color:white;
    padding:15px 20px 15px 20px;
    border-radius:5px;
    position:relative;
    letter-spacing: -0.02em;
    transition: all .2s;
    pointer-events: auto;
    touch-action: manipulation;

    @media (hover: hover) {
        &:hover {
            background:$blue;
            color:$white;
        }
    }
}

button,
input {
    background:none;
    border:0px;
    padding:0px;
    margin:0px;
    //line-height: 100%;
    font-size: 100%;
    cursor: pointer;
    color:inherit;
    outline: none;
    -webkit-tap-highlight-color: transparent !important;
    touch-action: manipulation;
}