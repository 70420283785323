/**
 * Footer
 */

footer {
    margin-top:60px;
    margin-bottom:20px;

    @media screen and (min-width: 1200px) {
        margin-top:100px;
    }

    @media screen and (min-width: 1400px) {
        margin-top:120px;
        margin-bottom:60px;
    }

    @media screen and (min-width: 1600px) {
        margin-top:200px;
        margin-bottom:100px;
    }

    .footer-blue {
        background:$light-blue;
        padding-top:60px;
        padding-bottom:60px;

        @media screen and (min-width: 1200px) {
            padding-top:80px;
            padding-bottom:80px;
        }

        @media screen and (min-width: 1400px) {
            padding-top:80px;
            padding-bottom:0px;
        }

        @media screen and (min-width: 1600px) {
            padding-top:40px;
        }

        @media screen and (min-width: 1800px) {
            padding-top:80px;
        }

        .container-large {
            @media screen and (min-width: 992px) {
                display:flex;
                justify-content: space-between;
                align-items: center;
            }

            @media screen and (min-width: 1400px) {
                align-items: flex-start;
            }

            @media screen and (min-width: 1600px) {
                align-items: center;
            }

            .img-container {
                margin-bottom:40px;

                @media screen and (min-width: 992px) {
                    margin-bottom:0px;
                    margin-right:60px;
                    width: 60%;
                }

                @media screen and (min-width:1200px) {
                    width: 50%;
                    margin-right:80px;
                }

                @media screen and (min-width: 1400px) {
                    margin-bottom:-80px;
                    margin-top:80px;
                }

                @media screen and (min-width:1600px) {
                    width: calc(40% + 160px);
                }

                img {
                    max-width: 100%;
                    width: 100%;;
                }
            }

            .text-container {
                @media screen and (min-width: 768px) {
                    padding-right:30px;
                    padding-left:30px;
                }

                @media screen and (min-width: 992px) {
                    width:50%;
                    padding-left:0px;
                    padding-right: 0px;
                }

                @media screen and (min-width: 1200px) {
                    width: 40%;
                }

                @media screen and (min-width: 1400px) {
                    padding-top:80px;
                }

                @media screen and (min-width: 1600px) {
                    padding-top:100px;
                    padding-bottom:40px;
                }

                @media screen and (min-width: 1800px) {
                    padding-top:160px;
                    padding-bottom:80px;
                    padding-right:160px;
                }

                br {
                    @media screen and (max-width: 767px) {
                        display:none;
                    }

                    @media screen and (min-width: 992px) and (max-width: 1199px) {
                        display:none;
                    }
                }
                
                .title {
                    margin-top:10px;
                }

                .infos {
                    margin-top:10px;
                }

                ul {
                    list-style: none;
                    margin-top:20px;
                    padding-left:30px;

                    @media screen and (min-width: 768px) {
                        padding-left:0px;
                    }

                    li {
                        a {
                            color:$blue;
                            transition: all .2s;
                            display:inline-flex;
                            align-items: center;
                            position:relative;

                            &:before {
                                content:'';
                                position:absolute;
                                width: 20px;
                                height: 1px;
                                left:-30px;
                                top:auto;
                                bottom:auto;
                                background:$blue;
                                transition: all .2s;
                            }

                            @media (hover: hover) {
                                &:hover {
                                    color:$dark-blue;

                                    &:before {
                                        left:-40px;
                                        width:30px;
                                        background:$dark-blue;
                                    }
                                }
                            }
                        }
                    }
                }

                .tiny {
                    font-style: italic;
                    font-size:rem(14px);
                    width: 260px;
                    margin-top:5px;
                    line-height:rem(24px);
                }
            }
        }
    }

    .legal {
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding-top:20px;

        @media screen and (min-width: 1400px) {
            //margin-left: calc(790px + 10%);
            height: 80px;
            padding-top:0px;
            justify-content: flex-end;
        }

        @media screen and (min-width: 1800px) {
            padding-right:160px;
        }
        
        ul {
            list-style: none;
            padding-left: 0px;
            display:flex;

            li {
                margin-right:5px;

                span {
                    color:$dark-gray;
                    font-size:rem(15px);
                    padding-left:5px;
                }
            }
        }

        a, button {
            font-size:rem(15px);
            color:$dark-gray;
            transition: all .2s;

            @media (hover: hover) {
                &:hover {
                    color:$black;
                }
            }
        }
    }
}