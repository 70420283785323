html {
  font-size:14px;
  --opacity-var: 0;
}

:root {
	--banner-height: 100vh;
  --col-intro-height: auto;
}

body {
  font-weight:500;
  font-family: $font-main;

  overflow-y:hidden;
  overflow-x: hidden;

  font-size:rem(16px);
  line-height: rem(32px);

  position: relative;
  background:$white;

  letter-spacing: 0.02em;
}

.container-large {
  max-width: 1920px;
  padding-left:30px;
  padding-right:30px;
  margin:0 auto;

  @media screen and (min-width: 768px) {
    padding-left:60px;
    padding-right:60px;
  }

  @media screen and (min-width: 1200px) {
    padding-left:80px;
    padding-right:80px;
  }
}

.container {
  max-width: 1600px;
  margin:0 auto;
  padding-left:30px;
  padding-right:30px;

  @media screen and (min-width: 768px) {
    padding-left:60px;
    padding-right:60px;
  }

  @media screen and (min-width: 1200px) {
    padding-left:80px;
    padding-right:80px;
  }
}

.container-tiny {
  max-width: 1040px;
  padding-left:30px;
  padding-right:30px;
  margin:0 auto;

  @media screen and (min-width: 768px) {
    padding-left:60px;
    padding-right:60px;
  }

  @media screen and (min-width: 1200px) {
    padding-left:80px;
    padding-right:80px;
  }
}

.img-container {
  picture {
    display:block;
  }
}

.main-content {
  margin-top:120px;
}

// HOMEPAGE
.--mission {
  margin-top:160px;
  position:relative;
  z-index:200;

  @media screen and (min-width: 768px) {
    margin-top:200px;
  }

  &.--homepage {
    .container-large {
      position:relative;
      z-index:200;
  
      @media screen and (min-width: 992px) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      .text-container {
        margin-bottom:40px;
        position: relative;
        z-index: 10;
  
        @media screen and (min-width: 1200px) {
          margin-bottom:200px;
          width: 50%;
        }
  
        @media screen and (min-width: 1600px) {
          margin-left:160px;
          width: 40%;
        }
        
        .infos {
          margin-top:40px;
          //background:red;

          @media screen and (min-width: 768px) {
            width: 55%;
          }
  
          @media screen and (min-width: 1200px) {
            margin-left:80px;
            width: auto;
          }
  
          @media screen and (min-width: 1600px) {
            margin-left:160px;
          }
  
          p {
            margin-top:20px;
            font-size:rem(18px);
            line-height: rem(34px);
          }
        }
      }

      .img-container {
        @media screen and (min-width: 768px) {
          width: 40%;
          position:absolute;
          bottom:0px;
          right:0px;
          z-index: 5;
        }

        @media screen and (min-width: 992px) {
          bottom:-120px;
        }
  
        @media screen and (min-width: 1200px) {
          position:relative;
          bottom: auto;
          right:auto;
        }
  
        img {
          max-width: 100%;
        }
      }
    }
  }

  &.--intern-page {
    .container {
      .text-container {
        margin-top: 200px;

        @media screen and (min-width: 768px) {
          margin-top: 300px;
        }

        @media screen and (min-width: 1200px) {
          margin-top: 360px;
        }

        h1 {
          border-bottom:2px solid rgba($blue, 0.3);
          padding-bottom:40px;

          @media screen and (min-width: 992px) {
            padding-bottom:60px;
          }

          @media screen and (min-width: 1200px) {
            padding-bottom:80px;
          }
        }

        p {
          font-family:$font-title;
          font-weight:400;
          letter-spacing: -0.025em;
          margin-top:40px;
          line-height:rem(36px);
          font-size:rem(24px);

          @media screen and (min-width: 992px) {
            margin-top:60px;
            width: 80%;
            line-height:rem(46px);
            font-size:rem(32px);
          }

          @media screen and (min-width: 1200px) {
            line-height:rem(52px);
            font-size:rem(40px);
            width: 70%;
          }

          @media screen and (min-width: 1400px) {
            margin-top:80px;
            width: 65%;
            line-height:rem(63px);
            font-size:rem(47px);
          }
        }
      }
    }

    .container-large {
      .img-container {
        margin-top:40px;

        @media screen and (min-width: 992px) {
          margin-top:80px;
        }

        @media screen and (min-width: 1200px) {
          margin-top:120px;
        }

        @media screen and (min-width: 1400px) {
          margin-top:160px;
        }

        picture {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.--intro {
  background:$light-gray;
  position:relative;
  z-index: 4;
  margin-bottom:60px;
  padding-bottom:60px;

  @media screen and (min-width: 992px) {
    margin-bottom:80px;
  }

  @media screen and (min-width: 1400px) {
    padding-bottom:100px;
    margin-bottom:120px;
  }

  @media screen and (min-width: 1600px) {
    margin-bottom:260px;
  }

  &.--homepage {
    margin-top:-100px;
    padding-top:160px;

    @media screen and (min-width: 768px) {
      margin-top:80px;
      padding-top:80px;
    }
  
    @media screen and (min-width: 1200px) {
      padding-top:220px;
      margin-top:-100px;
    }
  
    @media screen and (min-width: 1600px) {
      padding-top:240px;
    }
  
    @media screen and (min-width: 1800px) {
      padding-top:300px;
    }

    .--first {
      .text-container {
        .infos {
          p {
            margin-left:30px;
            position:relative;

            @media screen and (min-width: 768px) {
              margin-left:0px;
            }

            &:first-child {
              border-bottom:1px solid $blue;
              padding-bottom:30px;
              margin-bottom:30px;
            }

            span {
              position:absolute;
              top:0;
              color:$blue;
              left:-30px;
            }
          }
        }

        @media screen and (min-width: 768px) {
          width: 80%;
        }
  
        @media screen and (min-width: 992px) {
          width: 70%;
        }
  
        @media screen and (min-width: 1200px) {
          width: calc(50% - 80px);
          margin-left:80px;
        }
  
        @media screen and (min-width: 1400px) {
          width: calc(50% - 80px);
        }

        @media screen and (min-width: 1600px) {
          width: calc(40% - 160px);
          margin-left:320px;
        }
      }
    }

    .--second {
      justify-content: space-between;

      .imgs-container {   
        @media screen and (min-width:1200px) {
          width: 50%;
        }

        @media screen and (min-width:1600px) {
          margin-top:200px;
          width: calc(40% + 160px);
        }

        .--large {
          @media screen and (min-width:1600px) {
            margin-top:80px;
          }
        }
      }

      .text-container {
        @media screen and (min-width: 992px) {
          width: 70%;
        }

        @media screen and (min-width: 1200px) {
          width: 40%;
        }
      }
    }
  }

  &.--intern-page {
    margin-top:-60px;
    padding-top:120px;

    @media screen and (min-width: 992px) {
      margin-top:-80px;
      padding-top:160px;
    }
  
    @media screen and (min-width: 1200px) {
      margin-top:-100px;
      padding-top:240px;
    }

    @media screen and (min-width: 1400px) {
      margin-top:-100px;
      padding-top:260px;
    }
  
    @media screen and (min-width: 1800px) {
      padding-top:300px;
    }

    .--first {
      .text-container {
        .infos {
          @media screen and (min-width: 992px) {
            display:flex;
          }
          
          .col-left,
          .col-right {
            flex-shrink: 0;
      
            @media screen and (min-width: 1200px) {
              width: 50%;
            }
      
            @media screen and (min-width: 1400px) {
              width: 40%;
            }
          }

          .col-left {
            @media screen and (min-width: 992px) {
              margin-right: 60px;
            }

            @media screen and (min-width: 1200px) {
              margin-right: 160px;
            }

            h2 {
              @media screen and (min-width: 992px) {
                text-align:right;
              }
            }
          }

          p {
            @media screen and (max-width: 991px) {
              margin-top:20px;
            }

            & + ul {
              margin-top:20px;
            }

            a {
              color:$blue;
              transition: all .2s;
              text-decoration: underline;
              text-underline-offset: 4px;

              @media (hover: hover) {
                &:hover {
                    text-decoration-color: rgba($dark-blue, 0);
                    text-underline-offset: 6px;
                    color:$dark-blue;
                }
              }
            }
          }

          ul {
            list-style: none;
            padding-left:0px;

            li {
              display:flex;

              &:before {
                content:'';
                width: 20px;
                margin-right: 16px;
                height: 1px;
                background:$blue;
                display:block;
                margin-top:12px;
              }
            }
          }

          & + .infos {
            margin-top:60px;

            @media screen and (min-width: 992px) {
              margin-top:80px;
            }

            @media screen and (min-width: 1200px) {
              margin-top:120px;
            }

            @media screen and (min-width: 1400px) {
              margin-top:160px;
            }
          }
        }
      }
    
      &.--biomecanique {
        .text-container {
          h2 {
            @media screen and (min-width: 1400px) {
              margin-left: 240px;
            }
          }

          .infos {
            justify-content: space-between;

            @media screen and (min-width: 768px) {
              display:flex;
            }

            .col-left {
              margin-right: 0px;
              flex-shrink: 0;

              @media screen and (min-width: 768px) {
                width: calc(50% - 30px);
              }

              @media screen and (min-width: 1400px) {
                margin-left: 240px;
                width: calc(40% - 80px);
              }

            }

            .col-right {
              @media screen and (min-width: 768px) {
                width: calc(50% - 30px);
              }

              @media screen and (min-width: 992px) {
                width: 40%;
              }
            }
          }
        }
      }

      &:not(.--biomecanique) {
        .text-container {
          .infos {
            @media screen and (min-width: 1200px) {
              justify-content: space-between;
            }
          }
      
          .col-left,
          .col-right {
            @media screen and (min-width: 768px) {
              width: 80%;
            }
          }

          .col-left {
            @media screen and (min-width: 992px) {
              width: calc(50% - 80px);
            }

            @media screen and (min-width: 1200px) {
              width: calc(50% - 160px);
              margin-right: 0px;
            }

            @media screen and (min-width: 1600px) {
              width: calc(40% + 160px);
            }
          }

          .col-right {
            @media screen and (min-width: 992px) {
              width: 50%;
              flex-shrink: 1;
            }

            @media screen and (min-width: 1600px) {
              width: 40%;
              padding-right:160px;
            }
          }
        }
      }
    }

    .--second {
      align-items: center;

      .imgs-container {   
        @media screen and (min-width:1200px) {
          width: 50%;
        }

        @media screen and (min-width:1600px) {
          width: calc(40% + 160px);
          margin-top:100px;
        }

        .--large {
          @media screen and (min-width:1600px) {
            margin-top:160px;
          }
        }
      }

      .img-container {
        flex-shrink: 0;
        background:$white;
        display:flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width:992px) {
          width: calc(40% + 60px);
          //margin-right: 60px;
        }

        @media screen and (min-width:1200px) {
          width:50%
        }

        @media screen and (min-width:1600px) {
          width: calc(40% + 160px);
        }

        picture {
          width: 100%;
          padding:60px;

          @media screen and (min-width:768px) {
            padding:160px;
          }

          @media screen and (min-width:992px) {
            padding:60px;
          }

          @media screen and (min-width:1200px) {
            padding:120px;
          }

          @media screen and (min-width:1400px) {
            padding:160px;
          }

          img {
            width: 100%;
          }
        }

        & + .text-container {
          margin-left:0px;
          padding-right: 0px;
          margin-top:60px;

          @media screen and (min-width:992px) {
            margin-top:0px;
          }
        }
      }

      .text-container {
        @media screen and (min-width: 992px) {
          width: 70%;
        }

        @media screen and (min-width: 1200px) {
          width: 40%;
        }
      }

      &:not(.--square) {
        justify-content: space-between;
      }

      &.--square {
        @media screen and (min-width: 992px) {
          display:flex;
          justify-content: space-between;
        }

        .text-container {
          @media screen and (min-width: 992px) {
            margin-left:80px;
          }

          @media screen and (min-width: 1600px) {
            padding-right:160px;
            margin-left: 0;
          }
        }
      }
    }

    .--third {
      margin-top:60px;
      justify-content: space-between;
      display:flex;
      flex-direction: column-reverse;

      @media screen and (min-width: 992px) {
        margin-top:80px;
      }

      @media screen and (min-width: 1200px) and (max-width: 1399px) {
        align-items: center;
      }

      @media screen and (min-width: 1200px) {
        margin-top:120px;
        flex-direction: row;
      }

      @media screen and (min-width: 1400px) {
        margin-top:160px;
      }

      .text-container {
        margin-top:60px;
        flex-shrink: 0;

        @media screen and (min-width: 768px) {
          width: 80%;
        }

        @media screen and (min-width: 1200px) {
            width: 40%;
            margin-top:80px;
        }

        @media screen and (min-width: 1600px) {
            margin-left:160px;
            padding-right:80px;
        }
      }

      .imgs-container {
        display:flex;
        flex-shrink: 0;
        align-items: center;
        width: auto;
        flex-shrink: 0;

        @media screen and (min-width: 1200px) {
          width: 50%;
          margin-bottom:60px;
        }

        @media screen and (min-width:1600px) {
          margin-bottom:-200px;
          align-items: start;
          width:40%;
        }

        img {
          max-width: 100%;
        }

        .--large {
          margin-right:-80px;
          position:relative;
          z-index: 5;
          margin-top:80px;
        }
      }
    }
  }

  .--first {
    .text-container {
      h2 {
        @media screen and (min-width: 768px) {
          white-space: nowrap;
        }
      }
    }
  }

  .--second {
    margin-top:60px;

    @media screen and (min-width: 1200px) {
      margin-top:120px;
      display:flex;

    }

    @media screen and (min-width: 1400px) {
      margin-top:160px;
    }
    
    .imgs-container {
      display:flex;
      flex-shrink: 0;
      margin-bottom:60px;
      align-items: center;

      @media screen and (min-width:992px) {
      }

      @media screen and (min-width:1200px) {
        margin-bottom:0px;
        align-items: center;
        width: 50%;
      }

      @media screen and (min-width:1600px) {
        margin-bottom:-200px;
        align-items: start;
      }

      img {
        max-width: 100%;
      }

      .--large {
        margin-left:-80px;
      }
    }

    .text-container {
      @media screen and (min-width: 768px) {
        width: 80%;
      }

      @media screen and (min-width: 992px) {
        //margin-left:60px;
      }

      @media screen and (min-width: 1200px) {
        width: auto;
      }

      @media screen and (min-width: 1400px) {
        margin-left:120px;
      }

      @media screen and (min-width: 1600px) {
        margin-left:160px;
      }

      @media screen and (min-width: 1800px) {
        padding-right: 160px;
      }
    }
  }

  .subtitle {
    margin-bottom: 10px;
  }

  h2 + .infos {
    p {
      margin-top:20px;
    }
  }

  h3 {
    margin-top:40px;
    position:relative;
    font-size:rem(18px);

    @media screen and (min-width: 1200px) {
      margin-top:60px;
    }

    span {
      position:absolute;
      left:-60px;
      top:-10px;
      background:$white;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      display:flex;
      align-items: center;
      justify-content: center;
      opacity: 0;

      @media screen and (min-width: 1400px) {
        opacity: 1;
      }

      svg {
        width: 16px;
        fill:$blue;
      }
    }
  }
}

// BLOC SEMELLES
.--semelles {
  text-align: center;

  .text-container {
    h2 {
      margin-top: 10px;
    }

    .infos {
      margin-top:20px;
    }
  }

  .img-container {
    margin-top:40px;

    @media screen and (min-width: 1400px) {
      margin-top:60px;
    }

    img {
      max-width: 100%;
    }
  }
}

// COOKIES
#cc--main {
  #cm {
    #c-inr {
      display:flex;
      align-items: center;

      #c-txt {
        font-weight: 500;
        font-size:rem(12.5px);
      }

      #c-bns {
        margin-top:0px;
        font-size:rem(13px)
      }
    }
  }
}

// ERROR
.page-error {
  .main-wrapper {
    text-align: center;
    margin-top:270px;
    margin-bottom:160px;

    h1 {
      font-size:rem(50px);
      margin-bottom:10px;
    }

    .btn {
      margin-top:40px;
    }
  }
}