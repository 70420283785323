/**
 * Navbar
 */

header {
    pointer-events: none;

    // NAVBAR
    .navbar {
        left: 0px;
        right: 0px;
        top:0px;
        width: 100%;
        z-index: 9998;
        position: fixed;
        border-bottom:1px solid transparent;
        height: 200px;
        padding-top:70px;
        transition: transform .2s, height .2s, padding .2s;

        .container-large {
            display:flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (min-width: 1200px) {
                padding-left:80px;
                padding-right:80px;
            }

            @media screen and (min-width: 1800px) {
                padding-left:240px;
                padding-right:240px;
            }

            .navbar-left {
                a {
                    transition: all .2s;
                    pointer-events: auto;

                    @media (hover: hover) {
                        &:hover {
                            color:$gray;
                        }
                    }
                }
            }

            .navbar-right {
                .navbar-right-container {
                    @media screen and (max-width: 767px) {
                        background:$white;
                        position:fixed;
                        height: 100%;
                        width: 100%;
                        left: 0;
                        top:88px;
                        padding-left:30px;
                        padding-right: 30px;
                        z-index: 9999;
                        opacity: 0;
                        transition: all .2s;
                        flex-direction: column;
                        justify-content: flex-end;
                        padding-bottom: 120px;
                        overflow: auto;

                        .tiny {
                            font-style: italic;
                            font-size:rem(12px);
                            width: 240px;
                            margin-top:5px;
                            line-height:rem(20px);
                            opacity: .6;
                        }
                    }

                    @media screen and (max-width: 767px) and (min-height: 450px) {
                        display: flex;
                    }
                    
                    .mobile-contact {
                        @media screen and (min-width: 768px) {
                            display:none;
                        }
                    }
                }

                nav {
                    @media screen and (max-width: 767px) {
                        display:flex;
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    a {
                        margin-right: 15px;
                        color:$gray;
                        transition: all .2s;
                        pointer-events: auto;

                        @media screen and (max-width: 767px) {
                            font-size:rem(40px);
                            margin-top:20px;
                            letter-spacing: -0.02em;
                        }

                        @media screen and (min-width: 992px) {
                            margin-right: 40px; 
                        }

                        @media (hover: hover) {
                            &:hover {
                                color:$black;
                            }
                        }

                        &.--actif {
                            color:$black;
                        }
                    }
                }

                button {
                    background:$black;
                    color:white;
                    padding:15px 20px 15px 60px;
                    border-radius:5px;
                    position:relative;
                    letter-spacing: -0.02em;
                    transition: all .2s;
                    pointer-events: auto;

                    &#btnContactJS {
                        width: 136px;
                        padding:15px 20px 15px 60px;

                        @media screen and (max-width: 767px) {
                            display: none;
                        }

                        &:before,
                        &:after {
                            left: 20px;
                        }

                        &:before {
                            top:21px;
                        }
    
                        &:after {
                            bottom: 21px;
                        }

                        @media (hover: hover) {
                            &:hover {
                                &:before {
                                    top:19px;
                                }
    
                                &:after {
                                    bottom:19px;
                                }
                            }
                        }
                    }

                    &#btnBurgerJS {
                        padding:18px 25px; 
                        display:flex;
                        justify-content: center;
                        align-items: center;

                        @media screen and (min-width: 768px) {
                            display: none;
                        }

                        &:before,
                        &:after {
                            left: auto;
                            right: auto;
                        }

                        &:before {
                            top:14px;
                        }
    
                        &:after {
                            bottom: 14px;
                        }
                    }

                    span.close {
                        display:none;
                    }

                    @media (hover: hover) {
                        &:hover {
                            background:$blue;
                            color:$white;
                        }
                    }

                    &:before,
                    &:after {
                        content:'';
                        background:$white;
                        width: 30px;
                        height: 1px;
                        display:inline-block;
                        position:absolute;
                        transition: all .2s;
                    }       
                } 
            }
        }
    }
}

.popup-contact {
    position:fixed;
    height: calc(100% - 200px);
    width: 100%;
    left: 0;
    top: 200px;
    z-index: 0;
    pointer-events:none;
    opacity: 0;
    overflow-y: auto;

    .overlay {
        transition: all .2s;
        background:$light-blue;
        width: 100%;
        height: 100%;
        align-items: center;
        position:fixed;
        top:0;
        left: 0;
        opacity: 0;
        transform: translateX(100%);

        &:after {
            content:'';
            background:$white;
            width:192px;
            height: 100%;
            position:absolute;
            top:0;
            left: 0;

            @media screen and (min-width: 992px) {
                width:28%;
            }

            @media screen and (min-width: 1200px) {
                width:30%;
            }
        }
    }

    .popup-container {
        display:flex;

        @media screen and (min-height: 1200px) {
            align-items: center;
            height: 100%;
            margin-top:-70px;
        }

        .container-large {
            flex-grow: 1;

            @media screen and (max-height:799px) {
                align-items: flex-end !important;
                padding-bottom:60px;
            }
    
            @media screen and (min-width: 768px) {
                display:flex;
                justify-content: space-between;
            }

            @media screen and (min-width: 768px) and (max-width: 991px) {
                padding-left: 0px;
            }
    
            .img-container {
                z-index: 1;
                opacity: 0;
                transition-duration: .2s;
                transform: translateX(40px);

                @media screen and (max-height:799px) {
                    margin-top:-60px;
                }

                @media screen and (min-width: 768px) and (max-width: 991px) {
                    width: 50%;
                    flex-shrink: 0;
                }

                @media screen and (min-width:1200px) {
                    width: 50%;
                }
    
                @media screen and (min-width:1600px) {
                    width: calc(40% + 160px);
                }
    
                @media screen and (min-width: 1800px) and (min-height:900px) {
                }
    
                img {
                    max-width: 100%;
                    width: 100%;;
                }
            }
    
            .text-container {
                flex-grow: 1;
                z-index: 0;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-top:60px;
                opacity: 0;
                transition-duration: .2s;
                transform: translateX(-40px);
                padding-left:60px;

                @media screen and (min-width: 992px) {
                    padding-left:80px;
                    padding-top:80px;
                    flex-shrink: 0;
                }

                @media screen and (min-height: 800px){
                    padding-bottom:60px;
                }


                @media screen and (min-width: 992px) and (min-height: 800px){
                    padding-bottom:80px;
                }


                @media screen and (min-width: 1200px) {
                    padding-left:160px;
                }

                @media screen and (min-width: 1400px) {
                    padding-left:160px;
                }

                @media screen and (min-width: 1800px) and (min-height:900px) {
                    padding-top:160px;
                    padding-bottom:160px;
                }
    
                br {
                    @media screen and (max-width: 1799px) {
                        display:none;
                    }
                }
                
                .title {
                    margin-top:10px;
                }
    
                .infos {
                    margin-top:10px;

                    @media screen and (min-width: 992px) {
                        max-width: 450px;
                    }

                    @media screen and (max-width: 1399px) {
                        br {
                            display:none;
                        }
                    }
                }
    
                ul {
                    list-style: none;
                    padding-left:0px;
                    margin-top:20px;
    
                    li {
                        a {
                            color:$blue;
                            transition: all .2s;
                            display:inline-flex;
                            align-items: center;
                            position:relative;
    
                            &:before {
                                content:'';
                                position:absolute;
                                width: 20px;
                                height: 1px;
                                left:-30px;
                                top:auto;
                                bottom:auto;
                                background:$blue;
                                transition: all .2s;
                            }
    
                            @media (hover: hover) {
                                &:hover {
                                    color:$dark-blue;
    
                                    &:before {
                                        left:-40px;
                                        width:30px;
                                        background:$dark-blue;
                                    }
                                }
                            }
                        }
                    }
                }
    
                .tiny {
                    font-style: italic;
                    font-size:rem(14px);
                    width: 260px;
                    margin-top:5px;
                    line-height:rem(24px);
                }
            }
        }
    }
}

body {
    &.popup-open {
        .main-content,
        footer {
            opacity: 0;
        }
        
        .popup-contact {
            z-index: 9980;
            pointer-events:auto;
            opacity: 1;

            .overlay {
                opacity: 1;
                transform: translateX(0%);
            }

            .popup-container .container-large .text-container,
            .popup-container .container-large .img-container {
                opacity: 1;
                
            }

            .popup-container .container-large .img-container {
                transform: translateX(0px);
            }

            .popup-container .container-large .text-container {
                transform: translateX(0px);
            }
        }

        .navbar {
            background: transparent;
            height: 200px;
            padding-top:70px;
            border-color: transparent;
            
            .container-large {
                .navbar-right {
                    #btnContactJS {
                        background:$blue;
                        color:white;
                        padding-left:50px;

                        span {
                            &:not(.close) {
                                display:none;
                            }

                            &.close {
                                display:block;
                            }
                        }

                        @media (hover: hover) {
                            &:hover {
                                background:$dark-blue;
                                color:$white;

                                &:before {
                                    top:24px;
                                }

                                &:after {
                                    bottom: 23px;
                                }
                            }
                        }

                        &:before {
                            top:24px;
                            transform:rotate(45deg);
                            width: 20px;
                            left:26px;
                        }

                        &:after {
                            bottom: 23px;
                            transform:rotate(-45deg);
                            width: 20px;
                            left:26px;
                        }
                    }
                }
            }
        }
    }

    &:not(.popup-open):not(.burger-open) {
        header {
            &.--tiny {
                .navbar {
                    @media screen and (max-width: 767px) {
                        border-bottom:1px solid rgba($black, .1);
                    }
                }
            }
        }
    }

    &:not(.popup-open) {
        header {
            .navbar {
                @media screen and (max-width: 767px) {
                    top:0px;
                    height: 88px;
                    padding-top:26px;
                    transition: transform .2s, height .2s, padding .2s, opacity .2s;
                }
            }

            &.--tiny {
                .navbar {
                    @media screen and (max-width: 767px) {
                        background:$white;
                    }

                    @media screen and (min-width: 768px) {
                        border-bottom:1px solid rgba($black, .1);
                    }
                }
            }
          
            &.scroll-up.--tiny {
                .navbar {
                    @media screen and (min-width: 768px) {
                        background:$white;
                        top:0px;
                        height: 88px;
                        padding-top:20px;
                    }
                }
            }
        
            &.scroll-down.--tiny {
                .navbar {
                    @media screen and (min-width: 768px) {
                        transform: translateY(calc(-100%));
                    }
                }
            }
        }
    }

    &.burger-open {
        header {
            .navbar {
                border-bottom:1px solid transparent;
                background:$white;

                .container-large {
                    .navbar-right-container {
                        opacity: 1;
                        pointer-events: auto;

                        .mobile-contact {
                            margin-top:40px;

                            p:not(.tiny) {
                                font-size:rem(14px);
                                line-height: rem(24px);
                                max-width: 375px;

                                br {
                                    @media screen and (max-width: 575px) {
                                        display:none;
                                    }
                                }
                            }

                            ul {
                                list-style: none;
                                padding-left:0px;
                                margin-top:20px;

                                li {
                                    margin-left:30px;

                                    a {
                                        color:$blue;
                                        transition: all .2s;
                                        display:inline-flex;
                                        align-items: center;
                                        position:relative;
            
                                        &:before {
                                            content:'';
                                            position:absolute;
                                            width: 20px;
                                            height: 1px;
                                            left:-30px;
                                            top:auto;
                                            bottom:auto;
                                            background:$blue;
                                            transition: all .2s;
                                        }
            
                                        @media (hover: hover) {
                                            &:hover {
                                                color:$dark-blue;
            
                                                &:before {
                                                    left:-40px;
                                                    width:30px;
                                                    background:$dark-blue;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    #btnBurgerJS {
                        background:$blue;

                        @media (hover: hover) {
                            &:hover {
                                background:$dark-blue;
                                color:$white;
                            }
                        }

                        &:before {
                            top:17px;
                            transform:rotate(45deg);
                            width: 20px;
                        }

                        &:after {
                            bottom: 18px;
                            transform:rotate(-45deg);
                            width: 20px;
                        }
                    }
                }
            }
        }

        .main-content {
            pointer-events: none;
        }
    }

    &:not(.burger-open) {
        header {
            .navbar {
                .container-large {
                    @media screen and (max-width: 767px) {
                        .navbar-right-container {
                            pointer-events: none;

                            * {
                                pointer-events: none;
                            }
                        }
                    }

                    #btnBurgerJS {
                        @media (hover: hover) {
                            &:hover {
                                &:before {
                                    top:12px;
                                }
    
                                &:after {
                                    bottom:12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
