/**
 * Media
 */

// Fluid images for responsive purposes.

// If a `width` and/or `height` attribute have been explicitly defined, let’s not make the image fluid.

img[width],
img[height] {
  max-width: none;
}

figure {
  margin: 0;
}

picture {
  img {
    display:block;
  }
}

object,
iframe,
embed,
canvas,
video,
audio {
  max-width: 100%;
}
