/**
 * Colors
 */

$light-blue: #ebfafe;
$blue:#70a0ae;
$dark-blue:#305e6c;
$white: #ffffff;
$black: #000000;
$light-gray:#f6f6f6;
$gray: #8c8c8c;
$dark-gray:#707070;

// Default text color
$color-text: $black !default;

// Theme colors
$color-primary: $black;
$color-secondary: $black;


// Links
$color-link-color: $black !default;
$color-link-color-hover: darken($color-link-color, 20%) !default;
//$color-link-color-hover: color.adjust($color, $lightness: -20%) !default;


