/*!
 * Project: Project Name
 * Author: Name
 * E-mail: E-mail
 * Website:
 */

/**
 * This is where all of the stylesheets are compiled.
 * They are processed in the order they are imported
 * to ensure the consistent increase of specificity.
 *
 *    ITCSS Methodology: Inverted Triangle CSS
 *
 *    **************   1. Settings
 *     ************    2. Tools
 *      **********     3. Generic
 *       ********      4. Elements
 *        ******       5. Objects
 *         ****        6. Components
 *          **         7. Utilities
 *
 * 1. Settings.........Global configuration and variables.
 * 2. Tools............Functions and mixins.
 * 3. Generic..........Ground zero styles.
 *                     (normalizing.css, box-sizing etc...)
 * 4. Elements.........Unclassed (bare) HTML element
 *                     (like H1, Ul, A etc...)
 * 5. Objects..........Common non-cosmetic structural design patterns.
 *                     e.g containers, rows, grids, colums etc...
 * 6. Components.......Specific cosmetic elements of UI.
 *                     e.g. buttons, forms, header, footer etc...
 * 7. Utilities........Helpers and overrides.
 *
 *    Modules..........Multi-part components.
 *                     e.g. Navbar (HTML, CSS and JS).
 *    Shame............All the CSS, hacks and things we are not proud of.
 */

/* CONVERT PX TO REM */
@use 'sass:math';

@function rem($size) {
  @return math.div($size, 16px) * 1rem;
}
 
@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

 * {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
}



//@import 'node_modules/bootstrap/scss/bootstrap';

// Configuration
//@import "node_modules/bootstrap/scss/functions";
//@import "node_modules/bootstrap/scss/variables";
//@import "node_modules/bootstrap/scss/mixins";
//@import "node_modules/bootstrap/scss/maps";
//@import "node_modules/bootstrap/scss/utilities";

// Layout & components
//@import "node_modules/bootstrap/scss/root";
//@import "node_modules/bootstrap/scss/reboot";
//@import "node_modules/bootstrap/scss/containers";
//@import "node_modules/bootstrap/scss/grid";
//@import "node_modules/bootstrap/scss/tables";
//@import "node_modules/bootstrap/scss/forms";
//@import "node_modules/bootstrap/scss/buttons";
//@import "node_modules/bootstrap/scss/transitions";
//@import "node_modules/bootstrap/scss/dropdown";
//@import "node_modules/bootstrap/scss/button-group";
//@import "node_modules/bootstrap/scss/nav";
//@import "node_modules/bootstrap/scss/navbar";
//@import "node_modules/bootstrap/scss/card";
//@import "node_modules/bootstrap/scss/accordion";
//@import "node_modules/bootstrap/scss/breadcrumb";
//@import "node_modules/bootstrap/scss/pagination";
//@import "node_modules/bootstrap/scss/badge";
//@import "node_modules/bootstrap/scss/alert";
//@import "node_modules/bootstrap/scss/progress";
//@import "node_modules/bootstrap/scss/list-group";
//@import "node_modules/bootstrap/scss/close";
//@import "node_modules/bootstrap/scss/toasts";
//@import "node_modules/bootstrap/scss/modal";
//@import "node_modules/bootstrap/scss/tooltip";
//@import "node_modules/bootstrap/scss/popover";
//@import "node_modules/bootstrap/scss/spinners";
//@import "node_modules/bootstrap/scss/offcanvas";

//@import "node_modules/swiper/swiper.scss";
//@import "node_modules/swiper/modules/effect-fade/effect-fade.scss";

@import "settings/_colors.scss";
@import "settings/_global.scss";
@import "settings/_spacing.scss";
@import "settings/_typography.scss";

@import "generic/_box-sizing.scss";
@import "generic/_normalize.scss";
@import "generic/_print.scss";
@import "elements/_links.scss";
@import "elements/_media.scss";
@import "elements/_tables.scss";

@import "components/_buttons.scss";
@import "components/_cookies.scss";
@import "components/footer.scss";
@import "components/navbar.scss";


@import 'shame';
@import 'styles';
//@import 'mort.css';