/**
 * Typography
 */
 @font-face {
    font-family: 'geist';
    src: url('../assets/fonts/Geist/Geist-Regular.otf');
    src: local('Geist'), local('../assets/fonts/Geist/Geist-Regular'),
        url('../assets/fonts/Geist/Geist-Regular.woff2') format('woff2'),
        url('../assets/fonts/Geist/Geist-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'geist';
    src: url('../assets/fonts/Geist/Geist-SemiBold.otf');
    src: local('Geist'), local('../assets/fonts/Geist/Geist-SemiBold'),
        url('../assets/fonts/Geist/Geist-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/Geist/Geist-SemiBold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'geist-mono';
    src: url('../assets/fonts/GeistMono/GeistMono-Medium.otf');
    src: local('GeistMono'), local('../assets/fonts/GeistMono/GeistMono-Medium'),
        url('../assets/fonts/GeistMono/GeistMono-Medium.woff2') format('woff2'),
        url('../assets/fonts/GeistMono/GeistMono-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'geist-mono';
    src: url('../assets/fonts/GeistMono/GeistMono-Bold.otf');
    src: local('GeistMono'), local('../assets/fonts/GeistMono/GeistMono-Bold'),
        url('../assets/fonts/GeistMono/GeistMono-Bold.woff2') format('woff2'),
        url('../assets/fonts/GeistMono/GeistMono-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* 
GEIST - REGULAR / SEMIBOLD
GEIST MONO - MEDIUM / BOLD
*/

$font-main: "geist-mono", sans-serif;
$font-title: "geist", sans-serif;

a {
    color:inherit;
}

h1, h2, .--h2-like, h3, h4 {
    font-family: $font-title;
    line-height:120%;
}

.--homepage {
    h1 {
        font-size:rem(60px);
        letter-spacing: -.01em;
        margin-left:-7.5px;
    
        @media screen and (min-width: 768px) {
            font-size:rem(100px);
            letter-spacing: -.015em;
        }
    
        @media screen and (min-width: 992px) and (max-width: 1199px) {
            width: 75%;
        }
    
        @media screen and (min-width: 1600px) {
            font-size:rem(120px);
            letter-spacing: -.02em;
            white-space:nowrap;
        }
    
        span {
            font-style: italic;
            color:$blue;
            font-weight: 100;
            position:relative;
            display:inline-flex;
            align-items: center;
            justify-content: center;
    
            &:after {
                content:'';
                position:absolute;
                left: auto;
                top:auto;
                right: auto;
                bottom:auto;
                border-radius: 100%;
                display: block;
                background:$light-blue;
                z-index: -1;
                width: 150px;
                height: 150px;
                opacity: 0.7;
    
                @media screen and (min-width: 768px) {
                    width: 250px;
                    height: 250px;
                }
            }
        }
    }
}

.--intern-page {
    h1 {
        font-size:rem(42px);
        letter-spacing: -.01em;
        position:relative;

        @media screen and (min-width:992px) {
            font-size:rem(56px);
        }

        @media screen and (min-width:1200px) {
            font-size:rem(70px);
            letter-spacing: -.015em;
        }
    
        @media screen and (min-width: 1600px) {
            font-size:rem(84px);
            letter-spacing: -.02em;
            white-space:nowrap;
        }

        &:before {
            background:$light-blue;
            border-radius: 100%;
            content:'';
            position:absolute;
            top:-120px;
            left: -120px;
            width: 220px;
            height: 220px;
            z-index: -1;
        }
    }
}

h1 {
    font-weight: 400;

    strong {
        font-weight: 500;

        @media screen and (min-width: 992px) {
            display: block;
        }
    }
}

h2,
.--h2-like {
    font-weight: 400;
    font-size:rem(30px);
    letter-spacing: -.015em;

    @media screen and (min-width: 768px) {
        font-size:rem(36px);
        letter-spacing: -.0175em;
    }

    @media screen and (min-width: 1200px) {
        font-size:rem(40px);
        letter-spacing: -.02em;
    }

    @media screen and (min-width: 1400px) {
        font-size:rem(50px);
        letter-spacing: -.02em;
    }
}

h3 {
    //text-transform: uppercase;
    font-weight: 600;
    letter-spacing: -0.01em;
    line-height: 125%;
    font-family: $font-main;

    & + .infos p:first-child {
        margin-top:10px;
    }

    @media screen and (max-width: 1399px) {
        //font-size:rem(15px);
        //font-weight: 600;

        & + .infos p:first-child {
            margin-top:10px;
        }
    }
}

.subtitle {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size:rem(14px);
    line-height:150%;

    @media screen and (max-width: 767px) {
        font-size:rem(15px);
    }

    &.--blue {
        color:$blue;
    }
}